import { StoreAction } from "../../@core/constants";

const initialState = { size_data: [], get_size_data: [], update_size_data: [] }

export default function (state = initialState, action) {
    switch (action.type) {

        case StoreAction.SET_SIZE_DATA:
            return {
                ...state,
                size_data: action.payload,
            }

        case StoreAction.GET_SIZE_DATA:
            return {
                ...state,
                get_size_data: action.payload
            }
        case StoreAction.UPDATE_SIZE_DATA:
            return {
                ...state,
                update_size_data: action.payload
            }
        default:
            return state
    }
}