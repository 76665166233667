import { StoreAction } from "../../@core/constants";

const initialState = {
  store_data: [],
  get_store_data: []
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_STORE_DATA:
      return {
        ...state,
        store_data: action.payload,
      }
    case StoreAction.GET_STORE_DATA:
      return {
        ...state,
        get_store_data: action.payload
      }
    default:
      return state;
  }
}
