import { StoreAction } from "../../@core/constants";

const initialState = {
  staff_data: [],
  get_staff_data: [],
  update_staff_data:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_STAFF_DATA:
      return {
        ...state,
        staff_data: action.payload,
      };

    case StoreAction.GET_STAFF_DATA:
      return {
        ...state,
        get_staff_data: action.payload,
      };

        case StoreAction.UPDATE_STAFF_DATA:
        return {
          ...state,
          update_staff_data: action.payload,
        };

    default:
      return state;
  }
}
