import { StoreAction } from "../../@core/constants";

const initialState = {
  subcategory_data: [],
  get_subcategory_data : [],
  update_subcategory_data : []

};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_SUBCATEGORY_DATA:
      return {
        ...state,
        subcategory_data: action.payload,
      };
      case StoreAction.GET_SUBCATEGORY_DATA:
        return {
          ...state,
          get_subcategory_data: action.payload,
        };
        case StoreAction.UPDATE_SUBCATEGORY_DATA:
          return {
            ...state,
            update_subcategory_data: action.payload,
          };
    default:
      return state;
  }
}
