import { StoreAction } from "../../@core/constants";

const initialState = {
  
    login_data: [],
  };

  export default function (state = initialState, action) {
    switch (action.type) {
    //   case StoreAction.SET_REGISTRATION_DATA:
    //     return {
    //       ...state,
    //       registartion_data: action.payload,
    //     };
    //   case StoreAction.GET_REGISTRATION_DATA:
    //     return {
    //       ...state,
    //       get_registration_data: action.payload,
    //     };
      case StoreAction.GET_LOGIN_DATA:
        return {
          ...state,
          login_data: action.payload,
        };
      default:
        return state;
    }
  }