import { StoreAction } from "../../@core/constants";

const initialState = {
  category_data: [],
  get_category_data: [],
  update_category_data: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_CATEGORY_DATA:
      return {
        ...state,
        category_data: action.payload,
      };
    case StoreAction.GET_CATEGORY_DATA:
      return {
        ...state,
        get_category_data: action.payload,
      };
      case StoreAction.UPDATE_CATEGORY_DATA:
      return {
        ...state,
        update_category_data: action.payload,
      };

    default:
      return state;
  }
}
