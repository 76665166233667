import { StoreAction } from "../../@core/constants";

const initialState = { color_data: [], get_color_data: [], updated_color_data: [] }

export default function (state = initialState, action) {
    switch (action.type) {
        case StoreAction.SET_COLOR_DATA:
            return {
                ...state,
                color_data: action.payload,
            }
        case StoreAction.GET_COLOR_DATA:
            return {
                ...state,
                get_color_data: action.payload
            }
        case StoreAction.UPDATE_COLOR_DATA:
            return {
                ...state,
                updated_color_data: action.payload
            }
        default:
            return state
    }
}