export const StoreAction = {
    // SET_REGISTRATION_DATA:"SET_REGISTRATION_DATA",
    // GET_REGISTRATION_DATA:"GET_REGISTRATION_DATA",

    GET_LOGIN_DATA: "GET_LOGIN_DATA",
    SET_STORE_DATA: "SET_STORE_DATA",
    GET_STORE_DATA: "GET_STORE_DATA",
    SET_CATEGORY_DATA: "SET_CATEGORY_DATA",
    GET_CATEGORY_DATA: "GET_CATEGORY_DATA",
    UPDATE_CATEGORY_DATA: "UPDATE_CATEGORY_DATA",
    SET_STAFF_DATA: "SET_STAFF_DATA",
    GET_STAFF_DATA: "GET_STAFF_DATA",
    UPDATE_STAFF_DATA: "UPDATE_STAFF_DATA",
    SET_SUBCATEGORY_DATA: "SET_SUBCATEGORY_DATA",
    GET_SUBCATEGORY_DATA: "GET_SUBCATEGORY_DATA",
    UPDATE_SUBCATEGORY_DATA: "UPDATE_SUBCATEGORY_DATA",
    SET_COLOR_DATA: "SET_COLOR_DATA",
    GET_COLOR_DATA: "GET_COLOR_DATA",
    UPDATE_COLOR_DATA: "UPDATE_COLOR_DATA",
    SET_SIZE_DATA: "SET_SIZE_DATA",
    GET_SIZE_DATA: "GET_SIZE_DATA",
    UPDATE_SIZE_DATA: "UPDATE_SIZE_DATA",
}